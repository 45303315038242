import { useEffect, useState } from "react";

/**
 * Renders the current year. If this component is dynamically loaded on the
 * client, and the static-rendered current year has passed, it updates it with
 * JS.
 */
export default function CurrentYear() {
    // static-rendered year
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        // Update to the current year -- this only works if the componet is
        // client-loaded.
        setCurrentYear(new Date().getFullYear());
    }, []);

    return <span>{currentYear}</span>;
}
